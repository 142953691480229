button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

p {
  color: black;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite .4s linear;
  }
}

.App-header {
  background-color: rgb(239, 239, 239);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


@keyframes 

    App-logo-spin {
      0% {
        transform: translate(0, 0) rotate(0deg);
      }
  
      25% {
        transform: translate(5px, 10px) rotate(5deg);
      }
  
      50% {
        transform: translate(0, 0) rotate(0eg);
      }
  
      75% {
        transform: translate(-5px, 5px) rotate(-5deg);
      }
  
      100% {
        transform: translate(0, 0) rotate(0deg);
      }
    }
